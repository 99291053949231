import React, { Children, lazy, memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RouteObject, useRoutes } from "react-router-dom"
import { RootState } from "../store/store"
import userConfigConstants from "./userConfigConstants"
import NetworkTest from "../pages/Accounts/NetworkTest/NetworkTest"
import SecureFax from "../components/SecureFax/SecureFax"
import CallQueue from "../components/Call/CallQueue/CallQueue"
// import CallStats from "../components/Call/CallStats/CallStats"
import ChatWrapper2 from "../components/Chat/ChatWrapper2/ChatWrapper2"
import ChatRoute from "../components/Chat/ChatWrapper2/ChatRoute"
import BaseWaitingLobby from "../components/BaseWaitingLobby/BaseWaitingLobby"
import SunnyAI from "../pages/SunnyAI/SunnyAI"
import SunnyAIProviderWrapper from "../pages/SunnyAI/SunnyAIProviderWrapper"

// const ChatWrapper = lazy(() => import("../components/ChatWrapper/ChatWrapper"))
const ProtectedRoute = lazy(() => import("../components/routes/ProtectedRoute"))
const NonProtectedRoute = lazy(
  () => import("../components/routes/NonProtectedRoute")
)
const BaseMeeting = lazy(() => import("../components/BaseMeeting/BaseMeeting"))
const RedirectToMeetings = lazy(
  () => import("../components/RedirectToMeetings/RedirectToMeetings")
)
const NotFound = lazy(() => import("../pages/notFound/NotFound"))
const Landing = lazy(() => import("../pages/Landing"))
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"))
const PerfonalInfo = lazy(
  () => import("../pages/Accounts/PerfonalInfo/PerfonalInfo")
)
const CallerIdInfo = lazy(
  () => import("../pages/Accounts/CallerIdInfo/CallerIdInfo")
)
const PersonalMeetingInfo = lazy(
  () => import("../pages/Accounts/PersonalMeetingInfo/PersonalMeetingInfo")
)
const SelectTheme = lazy(
  () => import("../pages/Accounts/SelectTheme/SelectTheme")
)
const AccountSecurity = lazy(
  () => import("../pages/Accounts/AccountSecurity/AccountSecurity")
)
const VoiceMail = lazy(() => import("../pages/Accounts/VoiceMail/VoiceMail"))
const Login = lazy(() => import("../pages/login/login"))
const VideoSettings = lazy(
  () => import("../pages/Accounts/VideoSettings/VideoSettings")
)
const AudioSettings = lazy(
  () => import("../pages/Accounts/AudioSettings/AudioSettings")
)
const NotificationSettings = lazy(
  () => import("../pages/Accounts/NotificationSettings/NotificationSettings")
)
const AnsweringRules = lazy(
  () => import("../pages/Accounts/AnsweringRules/AnsweringRules")
)
const ConnectedAccounts = lazy(
  () => import("../pages/Accounts/ConnectedAccounts/ConnectedAccounts")
)
const ScheduleMeetingPage = lazy(
  () => import("../components/ScheduleMeetingPage/ScheduleMeetingPage")
)
const SplashLoader = lazy(() => import("../components/SplasLoader/SplasLoader"))
const MeetingsDetails = lazy(
  () => import("../pages/MeetingsDetails/MeetingsDetails")
)
const UpcomingMeetings = lazy(
  () => import("../pages/Meetings/UpcomingMeetings")
)
const PastMeeting = lazy(() => import("../pages/Meetings/PastMeeting"))
const Webinar = lazy(() => import("../pages/Meetings/Webinar/Webinar"))
const Recordings = lazy(() => import("../pages/Meetings/Recordings"))
const SharedRecordings = lazy(
  () => import("../pages/Meetings/SharedRecordings")
)
const Transcripts = lazy(() => import("../pages/Meetings/Transcripts"))
const MeetingProtectedRoute = lazy(
  () => import("../components/routes/MeetingProtectedRoute")
)
// const Meeting = lazy(() => import("../pages/Meeting"))
const JoinAuthMeeting = lazy(() => import("../pages/Meetings/JoinAuthMeeting"))
const TokenExpired = lazy(() => import("../pages/Meetings/TokenExpired"))
const Downloadcenter = lazy(
  () => import("../pages/downloadcenter/downloadcenter")
)
const Loading = lazy(() => import("../pages/Loading/Loading"))
const SelectUsers = lazy(() => import("../pages/SelectUsers/SelectUsers"))
const MfaSetup = lazy(() => import("../pages/MfaSetup/MfaSetup"))
const MfaPasscode = lazy(() => import("../pages/MfaPasscode/MfaPasscode"))
const Example = lazy(() => import("../pages/SSO"))
const Home = lazy(() => import("../pages/Home"))
const ForgotPassword = lazy(
  () => import("../pages/ForgotPassword/ForgotPassword")
)
const JoinMeeting = lazy(() => import("../components/JoniMeeting/JoinMeeting"))
const WebMiddleware = lazy(
  () => import("../components/WebMiddleware/WebMiddleware")
)
const WaitingLobby = lazy(
  () => import("../components/WaitingLobby/WaitingLobby")
)
const SSO = lazy(() => import("../pages/SSO"))
const DownloadOcgo = lazy(() => import("../pages/DownloadOcgo/DownloadOcgo"))
const EmptyScreen = lazy(() => import("../components/EmptyScreen/EmptyScreen"))
const WebCongratsModal = lazy(
  () => import("../components/WebCongratsModal/WebCongratsModal")
)
const DownloadMiddleware = lazy(
  () => import("../pages/Meetings/DownloadMiddleware")
)
const WebinarRegistration = lazy(
  () => import("../pages/Meetings/WebinarRegistration")
)
const Permission = lazy(() => import("../pages/Permission/Permission"))
const GoogleAuthRedirect = lazy(
  () => import("../components/GoogleAuthRedirect/GoogleAuthRedirect")
)
const SocialUsers = lazy(
  () => import("../components/AuthLogin/SocialUsers/SocialUsers")
)
const MicrosoftAuthRedirect = lazy(
  () => import("../components/MicrosoftAuthRedirect/MicrosoftAuthRedirect")
)
// const Chat = lazy(() => import("../pages/Chat/Chat"))
const PersonalMsg = lazy(() => import("../pages/Chat/PersonalMsg"))
const Call = lazy(() => import("../pages/call/Call"))
const AllCalls = lazy(() => import("../components/Call/call-history/AllCalls"))
const Voicemails = lazy(() => import("../components/Call/voicemail/Voicemails"))
const AllContacts = lazy(() => import("../components/Call/AllContacts"))
const Client = lazy(() => import("../components/Chat/templates/client/Client"))

const configNameToStateKey = {
  [userConfigConstants.CONNECT_CHAT_ENABLED]: "CONNECT_CHAT_ENABLED",
  [userConfigConstants.CONNECT_PHONE_ENABLED]: "CONNECT_PHONE_ENABLED",
  [userConfigConstants.CONNECT_MEET_ENABLED]: "CONNECT_MEET_ENABLED",
  [userConfigConstants.CONNECT_WEBINAR_ENABLED]: "CONNECT_WEBINAR_ENABLED",
  [userConfigConstants.CONNECT_FAX_ENABLED]: "CONNECT_FAX_ENABLED",
  [userConfigConstants.CONNECT_SUNNYAI_ENABLED]: "CONNECT_SUNNYAI_ENABLED",
  [userConfigConstants.WS_SERVERS]: "WS_SERVERS"
}

const FeatureFlags: Record<string, boolean | string> = {
  CONNECT_CHAT_ENABLED: true,
  CONNECT_PHONE_ENABLED: true,
  CONNECT_MEET_ENABLED: true,
  CONNECT_WEBINAR_ENABLED: true,
  CONNECT_FAX_ENABLED: true,
  CONNECT_SUNNYAI_ENABLED: true,
  WS_SERVERS: ""
}
const RoutesArr = () => {
  const [routes, setRoutes] = useState([])
  const { userConfig } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    for (let i = 0; i < userConfig.length; i += 1) {
      const configName = userConfig[i].config_name
      const stateKey = configNameToStateKey[configName]

      if (stateKey && stateKey != "WS_SERVERS") {
        FeatureFlags[stateKey] =
          userConfig[i].config_value === "yes"
      } else {
        FeatureFlags[stateKey] = userConfig[i].config_value
      }
    }
    const protectedChildRoutes = [
      {
        path: "/dashboard",
        element: <Dashboard />
      },
      {
        path: "/account/personal-info",
        element: <PerfonalInfo />
      },
      {
        path: "/account/caller-info",
        element: <CallerIdInfo />
      },
      {
        path: "/account/personal-meeting",
        element: <PersonalMeetingInfo />
      },
      {
        path: "/account/theme",
        element: <SelectTheme />
      },
      {
        path: "/account/change-account-security",
        element: <AccountSecurity />
      },
      {
        path: "/account/voice-mail",
        element: <VoiceMail />
      },
      {
        path: "/account/video",
        element: <VideoSettings />
      },
      {
        path: "/account/audio",
        element: <AudioSettings />
      },
      {
        path: "/account/notification-setting",
        element: <NotificationSettings />
      },
      {
        path: "/account/answer-rules",
        element: <AnsweringRules />
      },
      {
        path: "/account/connected-accounts",
        element: <ConnectedAccounts />
      },
      {
        path: "/account/network-test",
        element: <NetworkTest />
      },
      {
        path: "/splashloader",
        element: <SplashLoader />
      },
      {
        path: "/waiting/:roomId",
        element: <BaseWaitingLobby />
      }
    ]

    // if (FeatureFlags.CONNECT_MEET_ENABLED) {
    // As of now no solutions for meeting flag so putting true in condition
    if (FeatureFlags.CONNECT_MEET_ENABLED) {
      protectedChildRoutes.push(
        {
          path: "/schedule-meeting",
          element: <ScheduleMeetingPage />
        },
        {
          path: "/schedule-meeting/:action/:pk/:occuranceId",
          element: <ScheduleMeetingPage />
        },
        {
          path: "/meeting-details/:pk/:occuranceId",
          element: <MeetingsDetails />
        },
        {
          path: "/meetings/upcoming",
          element: <UpcomingMeetings />
        },
        {
          path: "/meetings/past-meeting",
          element: <PastMeeting />
        },
        {
          path: "/meetings/all-recordings",
          element: <Recordings />
        },
        {
          path: "/meetings/shared",
          element: <SharedRecordings />
        },
        {
          path: "/meetings/transcript",
          element: <Transcripts />
        },
        {
          path: "/join/auth/:roomId?",
          element: <JoinAuthMeeting />
        },
        {
          path: "/meeting/token-expired",
          element: <TokenExpired />
        },
        {
          path: "/meetings",
          element: <BaseMeeting />
        }
      )
    }

    if (FeatureFlags.CONNECT_WEBINAR_ENABLED) {
      protectedChildRoutes.push({
        path: "/meetings/webinar",
        element: <Webinar />
      })
    }

    if (FeatureFlags.CONNECT_CHAT_ENABLED) {
      protectedChildRoutes.push({
        path: "/chat",
        element: <ChatRoute />
      })

      protectedChildRoutes.push({
        path: "/chat/room/local/:id",
        element: <PersonalMsg isTemp />
      })

      protectedChildRoutes.push({
        path: "/chat/room/:id",
        element: <PersonalMsg />
      })
    }

    if (FeatureFlags.CONNECT_PHONE_ENABLED) {
      protectedChildRoutes.push({
        path: "/calls",
        element: <Call />
      })

      protectedChildRoutes.push({
        path: "/calls/call-queue",
        element: <CallQueue />
      })

      // protectedChildRoutes.push({
      //   path: "/calls/call-stats",
      //   element: <CallStats />
      // })

      protectedChildRoutes.push({
        path: "/calls/all-calls",
        element: <AllCalls />
      })

      protectedChildRoutes.push({
        path: "/calls/voicemail",
        element: <Voicemails />
      })

      protectedChildRoutes.push({
        path: "/calls/my-queues",
        element: <Call />
      })

      protectedChildRoutes.push({
        path: "/calls/my-stats",
        element: <Call />
      })

      protectedChildRoutes.push({
        path: "/calls/all-contacts",
        element: <AllContacts />
      })
    }

    if (FeatureFlags.CONNECT_FAX_ENABLED) {
      protectedChildRoutes.push({
        path: "/securefax",
        element: <SecureFax />
      })
    }
    if (FeatureFlags.CONNECT_SUNNYAI_ENABLED) {
      protectedChildRoutes.push({
        path: "sunnyai",
        element: <SunnyAI />
      })
    }

    const routesArr: RouteObject[] = [
      {
        element: (
          <SunnyAIProviderWrapper>
            <ChatWrapper2>
              <ProtectedRoute FeatureFlags={FeatureFlags} />
            </ChatWrapper2>
          </SunnyAIProviderWrapper>
        ),
        children: protectedChildRoutes
      },
      {
        element: <NonProtectedRoute />,
        children: [
          {
            path: "/",
            element: <Landing />
          },
          // {
          //   element: <MeetingProtectedRoute />,
          //   children: [
          //     {
          //       path: "/meeting/start-new/:meetingId",
          //       element: <Meeting />
          //     }
          //   ]
          // },
          {
            path: "/login",
            element: <Login />
          },
          {
            path: "/downloadcenter",
            element: <Downloadcenter />
          },
          {
            path: "/loading",
            element: <Loading />
          },
          {
            path: "/loginusers",
            element: <SelectUsers />
          },
          {
            path: "/mfasetup",
            element: <MfaSetup />
          },
          {
            path: "/mfaotp",
            element: <MfaPasscode />
          },
          {
            path: "/example",
            element: <Example />
          },
          {
            path: "/forgotpassword",
            element: <ForgotPassword />
          },
          {
            path: "/home",
            element: <Home />
          },
          {
            path: "/:meetingId",
            element: <RedirectToMeetings />
          },
          {
            path: "/webjoin/:roomId",
            element: <JoinMeeting />
          },
          {
            path: "/join/:roomId",
            element: <WebMiddleware />
          },
          {
            path: "/guest/waiting/:roomId",
            element: <WaitingLobby />
          },
          {
            path: "/api/v1/sso/onecloudsso/",
            element: <SSO />
          },
          {
            path: "/empty",
            element: <EmptyScreen />
          },
          {
            path: "/webcongratsmodal",
            element: <WebCongratsModal />
          },
          {
            path: "/join-meeting/:meetingId",
            element: <DownloadOcgo />
          },
          {
            path: "/download-recordings/:recordingId",
            element: <DownloadMiddleware />
          },
          {
            path: "/webinar/registration/:pk",
            element: <WebinarRegistration />
          },
          {
            path: "/permission",
            element: <Permission />
          },
          {
            path: "/google/auth/redirect",
            element: <GoogleAuthRedirect />
          },
          {
            path: "/social/auth/users",
            element: <SocialUsers />
          },
          {
            path: "/redirect/microsoft",
            element: <MicrosoftAuthRedirect />
          },
          {
            path: "/redirect/apple",
            element: <MicrosoftAuthRedirect />
          },
          {
            path: "/auth/microsoft",
            element: <MicrosoftAuthRedirect />
          },
          {
            path: "/guestmeeting",
            element: <BaseMeeting />
          },
          {
            path: "*",
            element: <NotFound />
          }
        ]
      }
    ]

    setRoutes(routesArr)
  }, [userConfig])

  return useRoutes(routes)
}

export default RoutesArr
